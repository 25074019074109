.custom-dropdown {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../assets/arrow.png") no-repeat right 10px center;
  background-size: 16px 16px; /* Adjust the size as needed */
  padding-right: 2.5rem; /* Adjust based on the size of the arrow */
}

.custom-dropdown::-ms-expand {
  display: none;
}
